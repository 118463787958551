import { Component, OnInit } from "@angular/core";
import { AuthService } from "@services/auth.service";

@Component({
  selector: "register",
  templateUrl: "./register.html",
  styles: [],
})
export class Register implements OnInit {
  constructor(public authService: AuthService) {}

  ngOnInit() {}
}
