export const afroComps = [
  {
    name: "Andela",
    location: "Nigeria",
    logo: "https://res.cloudinary.com/crunchbase-production/image/upload/c_lpad,h_170,w_170,f_auto,b_white,q_auto:eco,dpr_1/xikuc18kqmxz8yp3sezb",
    industry: "Software Development",
  },
  {
    name: "Flutterwave",
    location: "Nigeria",
    logo: "https://res.cloudinary.com/crunchbase-production/image/upload/c_lpad,h_170,w_170,f_auto,b_white,q_auto:eco,dpr_1/vmyyszxktkt2ms8pls7b",
    industry: "Fintech",
  },
  {
    name: "Jumia",
    location: "Nigeria",
    logo: "https://res.cloudinary.com/crunchbase-production/image/upload/c_lpad,h_170,w_170,f_auto,b_white,q_auto:eco,dpr_1/utbud9uxxpu6oekwtbfe",
    industry: "E-commerce",
  },
  {
    name: "Cellulant",
    location: "Kenya",
    logo: "https://res.cloudinary.com/crunchbase-production/image/upload/c_lpad,h_170,w_170,f_auto,b_white,q_auto:eco,dpr_1/v1491825456/gh1nr1v9hrb2qrbfqobi.png",
    industry: "Fintech",
  },
  {
    name: "Kobo360",
    location: "Nigeria",
    logo: "https://res.cloudinary.com/crunchbase-production/image/upload/c_lpad,h_170,w_170,f_auto,b_white,q_auto:eco,dpr_1/y4twupllyxt0mly3goc4",
    industry: "Logistics",
  },
  {
    name: "Interswitch",
    location: "Nigeria",
    logo: "https://res.cloudinary.com/crunchbase-production/image/upload/c_lpad,h_170,w_170,f_auto,b_white,q_auto:eco,dpr_1/v1472556226/zfujk6d2m0dnm2llrtnh.png",
    industry: "Fintech",
  },
  {
    name: "Safaricom",
    location: "Kenya",
    logo: "https://res.cloudinary.com/crunchbase-production/image/upload/c_lpad,h_170,w_170,f_auto,b_white,q_auto:eco,dpr_1/v1492835240/lvgxxlk8a5ekx7irna0d.png",
    industry: "Telecommunications",
  },
  {
    name: "AfricArena",
    location: "South Africa",
    logo: "https://cdn1.vc4a.com/media/2017/07/AfricArena-2017-Logo_logo-colour-copy-3-copie.png",
    industry: "Startup Ecosystem",
  },
  {
    name: "InTouch",
    location: "Senegal",
    logo: "https://intouchgroup.net/wp-content/themes/intouch-clarity/assets/img/Logo-Touch-Point.svg",
    industry: "Fintech",
  },
  {
    name: "Twiga Foods",
    location: "Kenya",
    logo: "https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcTgYtV8n6TnGQ9ZyUgcnjo6uaso8p4EbdaQBpmSY_UA2wjbrHgDflYDs9zjeWOUq5H-vcM&usqp=CAU",
    industry: "Agtech",
  },
  {
    name: "BitPesa",
    location: "Kenya",
    logo: "https://bitcoinist.com/wp-content/uploads/2015/02/bitpesa.png",
    industry: "Fintech",
  },
  {
    name: "M-Kopa Solar",
    location: "Kenya",
    logo: "https://www.gogla.org/sites/default/files/styles/member/public/mkopa_0.png?itok=nWhc0BEX",
    industry: "Renewable Energy",
  },
];
