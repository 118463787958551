import { Component, OnInit, Input } from "@angular/core";
import { AuthService } from "@services/auth.service";
import { Utilities } from "@services/utilities";
import { new_in } from "./new_info";
import cloneDeep from "lodash/cloneDeep";

@Component({
  selector: "d-new",
  templateUrl: "./d-new.html",
  styleUrls: ["./dash.scss"],
})
export class DNew implements OnInit {
  new_info = cloneDeep(new_in);

  constructor(public util: Utilities, public auth: AuthService) {}

  ngOnInit() {}

  sela(i) {
    this.new_info.applies[i].active = !this.new_info.applies[i].active;
  }

  seli(i) {
    this.new_info.industry[i].active = !this.new_info.industry[i].active;
  }

  profileUp() {
    let details: any = {};
    details.full_name = this.new_info.full_name;
    details.country = this.new_info.country;
    details.fields = this.new_info.applies
      .map((x) => {
        if (x.active) return x.name;
      })
      .filter((x) => x !== undefined);
    details.industries = this.new_info.industry
      .map((x) => {
        if (x.active) return x.name;
      })
      .filter((x) => x !== undefined);
    console.log(details);

    this.util.afs
      .doc(`users/${this.auth.userData.uid}`)
      .update({ details: details });
  }
}
