import { Injectable } from "@angular/core";
import { AngularFireAuth } from "@angular/fire/compat/auth";
import { AngularFireDatabase } from "@angular/fire/compat/database";
import { AngularFireAnalytics } from "@angular/fire/compat/analytics";
import { Router } from "@angular/router";
import { AngularFirestore } from "@angular/fire/compat/firestore";

@Injectable({
  providedIn: "root",
})
export class Utilities {
  constructor(
    public afa: AngularFireAuth,
    public afd: AngularFireDatabase,
    public afs: AngularFirestore,
    public lyt: AngularFireAnalytics,
    public router: Router
  ) {}

  analog(ev) {
    console.log(ev);
    this.lyt.logEvent(ev);
  }
}
