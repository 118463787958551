export const jobs = [
  {
    company: "Google",
    title: "Software Engineer",
    salary: "$120,000 - $150,000",
    location: "Mountain View, CA",
    logo: "https://upload.wikimedia.org/wikipedia/commons/thumb/5/53/Google_%22G%22_Logo.svg/2048px-Google_%22G%22_Logo.svg.png",
  },
  {
    company: "Apple",
    title: "iOS Developer",
    salary: "$130,000 - $160,000",
    location: "Cupertino, CA",
    logo: "https://upload.wikimedia.org/wikipedia/commons/thumb/f/fa/Apple_logo_black.svg/1667px-Apple_logo_black.svg.png",
  },
  {
    company: "Amazon",
    title: "Product Manager",
    salary: "$110,000 - $140,000",
    location: "Seattle, WA",
    logo: "https://upload.wikimedia.org/wikipedia/commons/thumb/4/4a/Amazon_icon.svg/2500px-Amazon_icon.svg.png",
  },
  {
    company: "Microsoft",
    title: "Data Scientist",
    salary: "$130,000 - $160,000",
    location: "Redmond, WA",
    logo: "https://upload.wikimedia.org/wikipedia/commons/thumb/4/44/Microsoft_logo.svg/2048px-Microsoft_logo.svg.png",
  },
  {
    company: "Facebook",
    title: "Product Designer",
    salary: "$120,000 - $150,000",
    location: "Menlo Park, CA",
    logo: "https://www.briggsdistrictlibrary.org/site-assets/images/facebook-logo.png/@@images/image.png",
  },
  {
    company: "Tesla",
    title: "Mechanical Engineer",
    salary: "$90,000 - $120,000",
    location: "Palo Alto, CA",
    logo: "https://upload.wikimedia.org/wikipedia/commons/e/e8/Tesla_logo.png",
  },
  {
    company: "Netflix",
    title: "Senior Software Engineer",
    salary: "$150,000 - $180,000",
    location: "Los Gatos, CA",
    logo: "https://www.spieltimes.com/wp-content/uploads/2022/07/Netflix-Logo.png",
  },
  {
    company: "Uber",
    title: "Senior Product Manager",
    salary: "$140,000 - $170,000",
    location: "San Francisco, CA",
    logo: "https://seeklogo.com/images/U/uber-logo-9B6033C292-seeklogo.com.png",
  },
  {
    company: "Airbnb",
    title: "Data Analyst",
    salary: "$100,000 - $130,000",
    location: "San Francisco, CA",
    logo: "https://static.dezeen.com/uploads/2014/07/Airbnb-rebrand-by-DesignStudio_dezeen_468_8.jpg",
  },
];
