export const environment = {
  production: true,
  firebase: {
    apiKey: "AIzaSyCnBMqyK-8KUpBPJMQHAvnoce9ESPCcFtY",
    authDomain: "tsa-techceed.firebaseapp.com",
    databaseURL: "https://tsa-techceed.firebaseio.com",
    projectId: "tsa-techceed",
    storageBucket: "tsa-techceed.appspot.com",
    messagingSenderId: "983014933751",
    appId: "1:983014933751:web:4b9935810389d8c10d7ff2",
    measurementId: "G-LH911LD8V3",
  },
};
