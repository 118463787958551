export const tech_events = [
  {
    name: "African Tech Summit",
    location: "Kigali, Rwanda",
    company: "Disrupt Africa",
    date: "March 21-22, 2023",
    ticket_cost: "$299 - $499",
    image:
      "https://images.pexels.com/photos/943096/pexels-photo-943096.jpeg?auto=compress&cs=tinysrgb&w=800",
  },
  {
    name: "Tech in Ghana Conference",
    location: "Accra, Ghana",
    company: "AB2020",
    date: "April 25-26, 2023",
    ticket_cost: "GHS 200 - GHS 500",
    image:
      "https://images.pexels.com/photos/319968/pexels-photo-319968.jpeg?auto=compress&cs=tinysrgb&w=800",
  },
  {
    name: "Africa Tech Week",
    location: "Cape Town, S. Africa",
    company: "Topco Media",
    date: "May 8-10, 2023",
    ticket_cost: "R6,900 - R8,900",
    image:
      "https://images.pexels.com/photos/8566472/pexels-photo-8566472.jpeg?auto=compress&cs=tinysrgb&w=800",
  },
  {
    name: "Nigeria Innovation Summit",
    location: "Lagos, Nigeria",
    company: "Innovators Hub",
    date: "June 5-6, 2023",
    ticket_cost: "NGN 20,000 - NGN 50,000",
    image:
      "https://images.pexels.com/photos/2255801/pexels-photo-2255801.jpeg?auto=compress&cs=tinysrgb&w=800",
  },
  {
    name: "East Africa Tech Summit",
    location: "Nairobi, Kenya",
    company: "Africa Tech Summit",
    date: "July 4-5, 2023",
    ticket_cost: "$149 - $399",
    image:
      "https://images.pexels.com/photos/9574320/pexels-photo-9574320.jpeg?auto=compress&cs=tinysrgb&w=800",
  },
  {
    name: "Egypt Tech Summit",
    location: "Cairo, Egypt",
    company: "Techne Summit",
    date: "Aug 15-16, 2023",
    ticket_cost: "EGP 1,500 - EGP 3,500",
    image: "https://i.imgur.com/Vbsk7t5.jpeg",
  },
  {
    name: "West Africa Com",
    location: "Dakar, Senegal",
    company: "Informa Tech",
    date: "Sept 12-14, 2023",
    ticket_cost: "XOF 150,000 - XOF 250,000",
    image:
      "https://images.pexels.com/photos/2034892/pexels-photo-2034892.jpeg?auto=compress&cs=tinysrgb&w=800",
  },
  {
    name: "Morocco Tech Summit",
    location: "Casablanca, Morocco",
    company: "Moroccan Center for Innovation and Entrepreneurship",
    date: "October 9-10, 2023",
    ticket_cost: "MAD 2,500 - MAD 5,000",
    image:
      "https://images.pexels.com/photos/7788009/pexels-photo-7788009.jpeg?auto=compress&cs=tinysrgb&w=800",
  },
  {
    name: "Techpoint Build",
    location: "Lagos, Nigeria",
    company: "Techpoint Africa",
    date: "Nov 14-15, 2023",
    ticket_cost: "NGN 30,000 - NGN 50,000",
    image:
      "https://images.pexels.com/photos/2777898/pexels-photo-2777898.jpeg?auto=compress&cs=tinysrgb&w=800",
  },
  {
    name: "Cape Town Tech Week",
    location: "Cape Town, S. Africa",
    company: "Cape Innovation and Technology Initiative",
    date: "Dec 4-8, 2023",
    ticket_cost: "R1,500 - R3,500",
    image:
      "https://images.pexels.com/photos/1209751/pexels-photo-1209751.jpeg?auto=compress&cs=tinysrgb&w=800",
  },
  {
    name: "Africa Digital Festival",
    location: "Joburg, S. Africa",
    company: "Festival of Media Africa",
    date: "January 15-18, 2024",
    ticket_cost: "ZAR 1,500 - ZAR 5,000",
    image:
      "https://images.pexels.com/photos/240572/pexels-photo-240572.jpeg?auto=compress&cs=tinysrgb&w=800",
  },
  {
    name: "Africa Tech Summit",
    location: "Kigali, Rwanda",
    company: "ATBN",
    date: "May 17-18, 2023",
    ticket_cost: "Starting at $300",
    image:
      "https://images.pexels.com/photos/46168/space-telescope-mirror-segments-james-webb-cosmos-46168.jpeg?auto=compress&cs=tinysrgb&w=800",
  },
  {
    name: "AI Expo Africa",
    location: "Cape Town, S. Africa",
    company: "AI Media Group",
    date: "Sept 7-8, 2023",
    ticket_cost: "Starting at ZAR 4,500",
    image:
      "https://images.pexels.com/photos/618079/pexels-photo-618079.jpeg?auto=compress&cs=tinysrgb&w=800",
  },
  {
    name: "Laravel Nigeria User Group Meetup",
    location: "Lagos, Nigeria",
    company: "Laravel Nigeria User Group",
    date: "April 1, 2023",
    ticket_cost: "Free",
    image:
      "https://images.pexels.com/photos/2921137/pexels-photo-2921137.jpeg?auto=compress&cs=tinysrgb&w=800",
  },
  {
    name: "Africa Digital Awards",
    location: "Accra, Ghana",
    company: "MobileWeb Ghana",
    date: "July 15, 2023",
    ticket_cost: "Starting at GHC 200",
    image:
      "https://images.pexels.com/photos/2085831/pexels-photo-2085831.jpeg?auto=compress&cs=tinysrgb&w=800",
  },
  {
    name: "PyCon Africa",
    location: "Nairobi, Kenya",
    company: "PyCon Africa",
    date: "Aug 10-12, 2023",
    ticket_cost: "Starting at KES 7,500",
    image:
      "https://images.pexels.com/photos/2159/flight-sky-earth-space.jpg?auto=compress&cs=tinysrgb&w=800",
  },
  {
    name: "Agile Africa Conference",
    location: "Joburg, S. Africa",
    company: "Agile Africa",
    date: "June 19-20, 2023",
    ticket_cost: "Starting at ZAR 4,000",
    image:
      "https://images.pexels.com/photos/868940/pexels-photo-868940.jpeg?auto=compress&cs=tinysrgb&w=800",
  },
  {
    name: "UX Lagos Meetup",
    location: "Lagos, Nigeria",
    company: "UX Lagos",
    date: "March 22, 2023",
    ticket_cost: "Free",
    image:
      "https://images.pexels.com/photos/326502/pexels-photo-326502.jpeg?auto=compress&cs=tinysrgb&w=800",
  },
  {
    name: "Africa Tech Week",
    location: "Cape Town, S. Africa",
    company: "Informa Markets",
    date: "March 13-17, 2023",
    ticket_cost: "Starting at ZAR 4,500",
    image:
      "https://images.pexels.com/photos/6770610/pexels-photo-6770610.jpeg?auto=compress&cs=tinysrgb&w=800",
  },
  {
    name: "eLearning Africa",
    location: "Kigali, Rwanda",
    company: "eLearning Africa",
    date: "May 25-27, 2023",
    ticket_cost: "Starting at $395",
    image:
      "https://images.pexels.com/photos/3183187/pexels-photo-3183187.jpeg?auto=compress&cs=tinysrgb&w=800",
  },
  {
    name: "DevFest Egypt",
    location: "Cairo, Egypt",
    company: "GDG Cairo",
    date: "April 29-30, 2023",
    ticket_cost: "Free",
    image:
      "https://images.pexels.com/photos/71241/pexels-photo-71241.jpeg?auto=compress&cs=tinysrgb&w=800",
  },
  {
    name: "Flutterwave Tech Meetup",
    location: "Lagos, Nigeria",
    company: "Flutterwave",
    date: "March 30, 2023",
    ticket_cost: "Free",
    image:
      "https://images.pexels.com/photos/164652/pexels-photo-164652.jpeg?auto=compress&cs=tinysrgb&w=800",
  },
  {
    name: "Blockchain Africa Conference",
    location: "Joburg, S. Africa",
    company: "Bitcoin Events",
    date: "March 28-29, 2023",
    ticket_cost: "Starting at ZAR 3,500",
    image:
      "https://images.pexels.com/photos/7788009/pexels-photo-7788009.jpeg?auto=compress&cs=tinysrgb&w=800",
  },
  {
    name: "DevCon Mauritius",
    location: "Port Louis, Mauritius",
    company: "DevCon Mauritius",
    date: "July 8-9, 2023",
    ticket_cost: "Starting at MUR 3,000",
    image:
      "https://images.pexels.com/photos/33153/raisting-sattelit-reception-signal.jpg?auto=compress&cs=tinysrgb&w=800",
  },
];
