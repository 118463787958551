import { Component, OnInit, Input } from "@angular/core";
import { AuthService } from "@services/auth.service";
import { Utilities } from "@services/utilities";
import { new_in } from "./new_info";
import cloneDeep from "lodash/cloneDeep";

@Component({
  selector: "d-home",
  templateUrl: "./d-home.html",
  styleUrls: ["./dash.scss"],
})
export class DHome implements OnInit {
  @Input() moi;

  h_menu = cloneDeep(new_in).applies;

  hi_menu = [
    {
      name: "Home",
      sicon: "assets/icons/home.svg",
    },
    {
      name: "Companies",
      sicon: "assets/icons/founder.svg",
    },
    {
      name: "Connect",
      sicon: "assets/icons/connect.svg",
    },
    {
      name: "Lounges",
      sicon: "assets/icons/lounges.svg",
    },
    {
      name: "Events",
      sicon: "assets/icons/events.svg",
    },
    {
      name: "Jobs",
      sicon: "assets/icons/jobs.svg",
    },
    {
      name: "Projects",
      sicon: "assets/icons/project.svg",
    },
    {
      name: "Stats",
      sicon: "assets/icons/stats.svg",
    },
  ];

  curr_menu = 0;

  constructor(public util: Utilities, public auth: AuthService) {}

  ngOnInit() {}
}
