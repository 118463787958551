import { Component, OnInit } from "@angular/core";
import { Utilities } from "@services/utilities";

@Component({
  selector: "m-stats",
  templateUrl: "./m-stats.html",
  styleUrls: ["./menu.scss"],
})
export class MStats implements OnInit {
  constructor(public util: Utilities) {}

  ngOnInit() {}
}
