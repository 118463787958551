import { NgModule } from "@angular/core";
import { Routes, RouterModule } from "@angular/router";

import { Login } from "./pages/login/login";
import { Register } from "./pages/register/register";
import { ForgotPassword } from "./pages/forgot-password/forgot-password";

import {
  AngularFireAuthGuard,
  canActivate,
  redirectLoggedInTo,
  redirectUnauthorizedTo,
} from "@angular/fire/compat/auth-guard";
import { Construction } from "./pages/construction/construction";
import { Dash } from "./pages/dash/dash";

const loggedIn = () => redirectLoggedInTo(["/dash"]);
const loggedOut = () => redirectUnauthorizedTo(["/login"]);

const routes: Routes = [
  {
    path: "",
    component: Construction,
  },
  {
    path: "login",
    component: Login,
    canActivate: [AngularFireAuthGuard],
    data: {
      authGuardPipe: loggedIn,
      title: "Login | TSA",
    },
  },
  {
    path: "register",
    component: Register,
    canActivate: [AngularFireAuthGuard],
    data: {
      authGuardPipe: loggedIn,
      title: "Register | TSA",
    },
  },
  {
    path: "forgot",
    component: ForgotPassword,
    canActivate: [AngularFireAuthGuard],
    data: {
      authGuardPipe: loggedIn,
      title: "Forgot Password | TSA",
    },
  },
  {
    path: "dash",
    component: Dash,
    canActivate: [AngularFireAuthGuard],
    data: {
      authGuardPipe: loggedOut,
      title: "Forgot Password | TSA",
    },
  },
  {
    path: "**",
    redirectTo: "",
    pathMatch: "full",
  },
];

@NgModule({
  imports: [
    RouterModule.forRoot(routes, {
      scrollPositionRestoration: "enabled",
      anchorScrolling: "enabled",
    }),
  ],
  exports: [RouterModule],
})
export class TsaRouting {}
