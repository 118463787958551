import { Component, OnInit } from "@angular/core";
import { Utilities } from "@services/utilities";
import { tech_events } from "./d-events";

@Component({
  selector: "m-events",
  templateUrl: "./m-events.html",
  styleUrls: ["./menu.scss"],
})
export class MEvents implements OnInit {
  eventsList = tech_events;

  constructor(public util: Utilities) {}

  ngOnInit() {}
}
