import { Component, OnInit } from "@angular/core";
import { AuthService } from "@services/auth.service";
import { Utilities } from "@services/utilities";
import { Observable } from "rxjs";

@Component({
  selector: "dash",
  templateUrl: "./dash.html",
  styleUrls: ["./dash.scss"],
})
export class Dash implements OnInit {
  moi$: Observable<any>;

  constructor(public util: Utilities, public auth: AuthService) {}

  async ngOnInit() {
    await new Promise((r) => setTimeout(r, 300));
    this.getU();
  }

  getU() {
    this.moi$ = this.util.afs
      .doc(`users/${this.auth.userData.uid}`)
      .valueChanges();
  }
}
