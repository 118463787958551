export const loungers = [
  {
    host: "orange-lion-81",
    topic: "Data Privacy and GDPR Compliance",
    category: "Conference",
    speakers: 2,
    listeners: 356,
  },
  {
    host: "gray-tiger-44",
    topic: "Quantum Computing and Cryptography",
    category: "Workshop",
    speakers: 10,
    listeners: 104,
  },
  {
    host: "pink-wolf-71",
    topic: "Virtual Reality in Education",
    category: "Panel Discussion",
    speakers: 12,
    listeners: 123,
  },
  {
    host: "purple-raven-50",
    topic: "The Future of Blockchain",
    category: "Hackathon",
    speakers: 11,
    listeners: 97,
  },
  {
    host: "black-tiger-16",
    topic: "Cloud Native Application Development",
    category: "Seminar",
    speakers: 11,
    listeners: 155,
  },
  {
    host: "red-fox-19",
    topic: "DevOps and Continuous Delivery",
    category: "Workshop",
    speakers: 7,
    listeners: 85,
  },
  {
    host: "white-eagle-91",
    topic: "Machine Learning for Finance",
    category: "Hackathon",
    speakers: 8,
    listeners: 192,
  },
  {
    host: "orange-fox-68",
    topic: "Augmented Reality in Marketing",
    category: "Workshop",
    speakers: 6,
    listeners: 200,
  },
  {
    host: "yellow-tiger-22",
    topic: "Digital Transformation in Healthcare",
    category: "Seminar",
    speakers: 4,
    listeners: 171,
  },
  {
    host: "blue-wolf-6",
    topic: "Web Accessibility and Inclusive Design",
    category: "Panel Discussion",
    speakers: 6,
    listeners: 272,
  },
  {
    host: "green-lion-4",
    topic: "Cyber Threat Intelligence",
    category: "Seminar",
    speakers: 6,
    listeners: 36,
  },
  {
    host: "yellow-lion-58",
    topic: "Artificial Intelligence for Business",
    category: "Hackathon",
    speakers: 11,
    listeners: 214,
  },
  {
    host: "red-tiger-11",
    topic: "Software Development Best Practices",
    category: "Panel Discussion",
    speakers: 3,
    listeners: 53,
  },
  {
    host: "green-tiger-44",
    topic: "Open Source Software in Business",
    category: "Conference",
    speakers: 1,
    listeners: 54,
  },
  {
    host: "purple-raven-19",
    topic: "Big Data and Predictive Analytics",
    category: "Workshop",
    speakers: 5,
    listeners: 318,
  },
  {
    host: "blue-hawk-33",
    topic: "Mobile App Development and UX",
    category: "Panel Discussion",
    speakers: 4,
    listeners: 342,
  },
  {
    host: "pink-tiger-38",
    topic: "Cloud Computing and Cybersecurity",
    category: "Hackathon",
    speakers: 5,
    listeners: 231,
  },
];
