import { Component, OnInit } from "@angular/core";
import { Utilities } from "@services/utilities";

@Component({
  selector: "m-home",
  templateUrl: "./m-home.html",
  styleUrls: ["./m-home.scss"],
})
export class MHome implements OnInit {
  constructor(public util: Utilities) {}

  ngOnInit() {}
}
