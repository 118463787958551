import { Component, OnInit } from "@angular/core";
import { Utilities } from "@services/utilities";
import { jobs } from "./d-jobs";

@Component({
  selector: "m-jobs",
  templateUrl: "./m-jobs.html",
  styleUrls: ["./menu.scss"],
})
export class MJobs implements OnInit {
  jobListings = jobs;
  constructor(public util: Utilities) {}

  ngOnInit() {}
}
