export const new_in = {
  full_name: "",
  country: "",
  applies: [
    {
      name: "Student",
      active: false,
      icon: "fas fa-graduation-cap",
      sicon: "assets/icons/student.svg",
    },
    {
      name: "Professor",
      active: false,
      icon: "fas fa-chalkboard-teacher",
      sicon: "assets/icons/prof.svg",
    },
    {
      name: "Scientist",
      active: false,
      icon: "fas fa-flask",
      sicon: "assets/icons/scientist.svg",
    },
    {
      name: "Engineer",
      active: false,
      icon: "fas fa-cogs",
      sicon: "assets/icons/engineer.svg",
    },
    {
      name: "Founder",
      active: false,
      icon: "fas fa-building",
      sicon: "assets/icons/founder.svg",
    },
    {
      name: "Investor",
      active: false,
      icon: "fas fa-coins",
      sicon: "assets/icons/investor.svg",
    },
  ],
  industry: [
    {
      name: "Energy",
      active: false,
      icon: "fas fa-car-battery",
      sicon: "assets/icons/industry/energy.svg",
    },
    {
      name: "Robotics",
      active: false,
      icon: "fas fa-robot",
      sicon: "assets/icons/industry/robotics.svg",
    },
    {
      name: "A.I.",
      active: false,
      icon: "fas fa-microchip",
      sicon: "assets/icons/industry/ai.svg",
    },
    {
      name: "Space Tech",
      active: false,
      icon: "fas fa-satellite",
      sicon: "assets/icons/industry/space.svg",
    },
    {
      name: "Bio Tech",
      active: false,
      icon: "fas fa-dna",
      sicon: "assets/icons/industry/biotech.svg",
    },
    {
      name: "Agritech",
      active: false,
      icon: "fas fa-tractor",
      sicon: "assets/icons/industry/agri.svg",
    },
    {
      name: "Education",
      active: false,
      icon: "fas fa-university",
      sicon: "assets/icons/industry/education.svg",
    },
    {
      name: "Logistics",
      active: false,
      icon: "fas fa-plane",
      sicon: "assets/icons/industry/logistics.svg",
    },
    {
      name: "Tele Comms",
      active: false,
      icon: "fas fa-satellite-dish",
      sicon: "assets/icons/industry/telec.svg",
    },
    {
      name: "Blockchain",
      active: false,
      icon: "fab fa-bitcoin",
      sicon: "assets/icons/industry/blockchain.svg",
    },
    {
      name: "Fin Tech",
      active: false,
      icon: "fas fa-money-bill-wave",
      sicon: "assets/icons/industry/fintech.svg",
    },
    {
      name: "Gaming",
      active: false,
      icon: "fas fa-gamepad",
      sicon: "assets/icons/industry/gaming.svg",
    },
    {
      name: "Cyber Security",
      active: false,
      icon: "fas fa-shield-alt",
      sicon: "assets/icons/industry/cybersec.svg",
    },
  ],
};
