import { Component, OnInit } from "@angular/core";
import { Utilities } from "@services/utilities";

@Component({
  selector: "construction",
  templateUrl: "./construction.html",
  styleUrls: ["./construction.scss"],
})
export class Construction implements OnInit {
  constructor(public util: Utilities) {}

  ngOnInit() {}
}
