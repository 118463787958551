export const africanProfiles = [
  {
    name: "Adebayo Ogunlesi",
    city_country: "Lagos, Nigeria",
    profile_image:
      "https://images.pexels.com/photos/1967902/pexels-photo-1967902.jpeg?auto=compress&cs=tinysrgb&w=800",
    career: "Investment banker",
  },
  {
    name: "Chimamanda Ngozi Adichie",
    city_country: "Abba, Nigeria",
    profile_image:
      "https://images.pexels.com/photos/2381069/pexels-photo-2381069.jpeg?auto=compress&cs=tinysrgb&w=800",
    career: "Author",
  },
  {
    name: "Akintoye Akindele",
    city_country: "Lagos, Nigeria",
    profile_image:
      "https://images.pexels.com/photos/1405963/pexels-photo-1405963.jpeg?auto=compress&cs=tinysrgb&w=800",
    career: "Founder",
  },
  {
    name: "Lupita Nyong’o",
    city_country: "Mexico City, Mexico",
    profile_image:
      "https://images.pexels.com/photos/1126727/pexels-photo-1126727.jpeg?auto=compress&cs=tinysrgb&w=800",
    career: "Student",
  },
  {
    name: "Trevor Noah",
    city_country: "Johannesburg, South Africa",
    profile_image:
      "https://images.pexels.com/photos/2024901/pexels-photo-2024901.jpeg?auto=compress&cs=tinysrgb&w=800",
    career: "Cyber Security",
  },
  {
    name: "Dambisa Moyo",
    city_country: "Lusaka, Zambia",
    profile_image:
      "https://images.pexels.com/photos/1006202/pexels-photo-1006202.jpeg?auto=compress&cs=tinysrgb&w=800",
    career: "Statistician",
  },
  {
    name: "Siyabulela Xuza",
    city_country: "Mthatha, South Africa",
    profile_image:
      "https://images.pexels.com/photos/2216607/pexels-photo-2216607.jpeg?auto=compress&cs=tinysrgb&w=800",
    career: "Rocket scientist",
  },
  {
    name: "Adesua Etomi-Wellington",
    city_country: "Warri, Nigeria",
    profile_image:
      "https://images.pexels.com/photos/1625775/pexels-photo-1625775.jpeg?auto=compress&cs=tinysrgb&w=800",
    career: "Astrophysicist",
  },
  {
    name: "Ngozi Okonjo-Iweala",
    city_country: "Ogwashi-Uku, Nigeria",
    profile_image:
      "https://images.pexels.com/photos/2737046/pexels-photo-2737046.jpeg?auto=compress&cs=tinysrgb&w=800",
    career: "Dev Ops",
  },
  {
    name: "Trevor Stuurman",
    city_country: "Johannesburg, South Africa",
    profile_image:
      "https://images.pexels.com/photos/2719416/pexels-photo-2719416.jpeg?auto=compress&cs=tinysrgb&w=800",
    career: "Investor",
  },
  {
    name: "Kemi Adetiba",
    city_country: "Lagos, Nigeria",
    profile_image:
      "https://images.pexels.com/photos/1068207/pexels-photo-1068207.jpeg?auto=compress&cs=tinysrgb&w=800",
    career: "Scientist",
  },
  {
    name: "Fatima Al-Fihri",
    city_country: "Fes, Morocco",
    profile_image:
      "https://images.pexels.com/photos/2033287/pexels-photo-2033287.jpeg?auto=compress&cs=tinysrgb&w=800",
    career: "Founder",
  },
  {
    name: "Ngozi Okonjo-Iweala",
    city_country: "Ogwashi-Uku, Nigeria",
    profile_image:
      "https://images.pexels.com/photos/2737046/pexels-photo-2737046.jpeg?auto=compress&cs=tinysrgb&w=800",
    career: "Dev Ops",
  },
  {
    name: "Trevor Stuurman",
    city_country: "Johannesburg, South Africa",
    profile_image:
      "https://images.pexels.com/photos/2719416/pexels-photo-2719416.jpeg?auto=compress&cs=tinysrgb&w=800",
    career: "Investor",
  },
  {
    name: "Kemi Adetiba",
    city_country: "Lagos, Nigeria",
    profile_image:
      "https://images.pexels.com/photos/1068207/pexels-photo-1068207.jpeg?auto=compress&cs=tinysrgb&w=800",
    career: "Scientist",
  },
  {
    name: "Fatima Al-Fihri",
    city_country: "Fes, Morocco",
    profile_image:
      "https://images.pexels.com/photos/2033287/pexels-photo-2033287.jpeg?auto=compress&cs=tinysrgb&w=800",
    career: "Founder",
  },
  {
    name: "Kemi Adetiba",
    city_country: "Lagos, Nigeria",
    profile_image:
      "https://images.pexels.com/photos/1068207/pexels-photo-1068207.jpeg?auto=compress&cs=tinysrgb&w=800",
    career: "Scientist",
  },
  {
    name: "Fatima Al-Fihri",
    city_country: "Fes, Morocco",
    profile_image:
      "https://images.pexels.com/photos/2033287/pexels-photo-2033287.jpeg?auto=compress&cs=tinysrgb&w=800",
    career: "Founder",
  },
];
