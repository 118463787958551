import { Component, OnInit } from "@angular/core";
import { AuthService } from "@services/auth.service";

@Component({
  selector: "forgot-password",
  templateUrl: "./forgot-password.html",
  styles: [],
})
export class ForgotPassword implements OnInit {
  constructor(public authService: AuthService) {}

  ngOnInit() {}
}
