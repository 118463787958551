import { Component, OnInit } from "@angular/core";
import { AuthService } from "@services/auth.service";

@Component({
  selector: "login",
  templateUrl: "./login.html",
  styles: [],
})
export class Login implements OnInit {
  constructor(public authService: AuthService) {}

  ngOnInit() {}
}
