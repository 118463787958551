import { BrowserModule } from "@angular/platform-browser";
import { BrowserAnimationsModule } from "@angular/platform-browser/animations";
import { NgModule } from "@angular/core";
import { FormsModule } from "@angular/forms";
import { AngularFireModule } from "@angular/fire/compat";
import { AngularFireAuthModule } from "@angular/fire/compat/auth";
import { AngularFirestoreModule } from "@angular/fire/compat/firestore";
import {
  AngularFireAnalyticsModule,
  ScreenTrackingService,
} from "@angular/fire/compat/analytics";
import { AngularSvgIconModule } from "angular-svg-icon";

import { TsaRouting } from "./tsa-routing";

import { environment } from "../environments/environment";

import { Tsa } from "./tsa";
import { Login } from "./pages/login/login";
import { Register } from "./pages/register/register";
import { ForgotPassword } from "./pages/forgot-password/forgot-password";
import { Construction } from "./pages/construction/construction";
import { AuthService } from "@services/auth.service";
import { ScullyLibModule } from "@scullyio/ng-lib";
import { Utilities } from "@services/utilities";
import { Dash } from "./pages/dash/dash";
import { DNew } from "./pages/dash/d-new";
import { DHome } from "./pages/dash/d-home";
import { MHome } from "./pages/dash/menu/m-home";
import { MProject } from "./pages/dash/menu/m-projects";
import { MComps } from "./pages/dash/menu/m-comps";
import { MConnect } from "./pages/dash/menu/m-connect";
import { MLounges } from "./pages/dash/menu/m-lounges";
import { MEvents } from "./pages/dash/menu/m-events";
import { MStats } from "./pages/dash/menu/m-stats";
import { MJobs } from "./pages/dash/menu/m-jobs";

@NgModule({
  declarations: [
    Tsa,
    Login,
    Register,
    ForgotPassword,
    Construction,
    Dash,
    DNew,
    DHome,
    MHome,
    MProject,
    MComps,
    MConnect,
    MLounges,
    MEvents,
    MStats,
    MJobs,
  ],
  imports: [
    BrowserModule,
    BrowserAnimationsModule,
    FormsModule,
    TsaRouting,
    AngularFireModule.initializeApp(environment.firebase),
    AngularFireAuthModule,
    AngularFirestoreModule,
    AngularFireAnalyticsModule,
    AngularSvgIconModule.forRoot(),
    ScullyLibModule,
  ],
  providers: [AuthService, Utilities, ScreenTrackingService],
  bootstrap: [Tsa],
})
export class TsaModule {}
