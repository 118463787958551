import { Component, OnInit } from "@angular/core";
import { Utilities } from "@services/utilities";
import { afroComps } from "./d-comps";

@Component({
  selector: "m-comps",
  templateUrl: "./m-comps.html",
  styleUrls: ["./menu.scss"],
})
export class MComps implements OnInit {
  comps = afroComps;

  constructor(public util: Utilities) {}

  ngOnInit() {}
}
