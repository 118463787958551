import { Component, OnInit } from "@angular/core";
import { Utilities } from "@services/utilities";
import { loungers } from "./d-lounges";
import { africanProfiles } from "./d-people";

@Component({
  selector: "m-lounges",
  templateUrl: "./m-lounges.html",
  styleUrls: ["./menu.scss"],
})
export class MLounges implements OnInit {
  lounges = loungers;
  peeps = africanProfiles;

  constructor(public util: Utilities) {}

  ngOnInit() {}
}
