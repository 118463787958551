import { Component, OnInit } from "@angular/core";
import { Utilities } from "@services/utilities";
import { africanProfiles } from "./d-people";

@Component({
  selector: "m-connect",
  templateUrl: "./m-connect.html",
  styleUrls: ["./menu.scss"],
})
export class MConnect implements OnInit {
  connects = africanProfiles;

  constructor(public util: Utilities) {}

  ngOnInit() {}
}
